@import 'colors';

.container {
  position: relative;
}
.navbar {
  box-shadow: $boxShadow;
  justify-content: space-between;
  height: 64px;
  position: relative;
  .logo {
    height: 40px;
  }
}
.user {
  position: absolute;
  top: 64px;
  width: 100%;
  background-color: rgba($primary, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  span {
    opacity: 0.5;
  }
}
