@import 'colors';
@import 'breakpoints';

.container {
  margin-bottom: 1rem;
  background-color: white;
  box-shadow: $boxShadow;
  border-radius: 10px;
  padding: 1rem;
}

.visible {
  display: flex;
  justify-content: space-between;

  .buttons {
    button {
      background-color: white;
      border: 0;
      padding: 0;
      width: 3rem;
      height: 3rem;
      border-radius: 10px;
      transition: background-color 0.4s ease;
      cursor: pointer;

      &:first-child {
        margin-right: 1rem;
      }

      svg {
        width: 3rem;
        height: 3rem;
        fill: $secondary;
      }
      &:hover {
        background-color: rgba($secondary, 0.05);
      }
      &.active {
        background-color: rgba($primary, 0.05);
      }
    }
  }
}

.preview {
  width: fit-content;
  .bars {
    display: flex;
    .bar {
      text-align: center;
      &.barLeft {
        margin-right: 1.5rem;
      }
      svg {
        border-radius: 10px;
        box-shadow: $boxShadow;
      }
    }
  }
}
