@import 'colors';

.container {
  padding-top: 1rem;
}

.bars {
  display: flex;
  .bar {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    &.barLeft {
      margin-right: 1.5rem;
    }
    svg {
      border-radius: 10px;
      box-shadow: $boxShadow;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}

.values {
  & > div {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.loading {
  padding: 1.5rem 1rem 1rem 1rem;
}
