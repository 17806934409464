@import 'colors';

.container {
  padding-top: 1rem;
}

.buttons {
  text-align: center;
  margin-bottom: 1rem;
  button {
    border: 0;
    background-color: transparent;
    opacity: 0.5;
    cursor: pointer;
    &.active {
      text-decoration: underline !important;
      opacity: 1;
    }
  }
}

.lineCharts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
  canvas {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    70deg,
    rgba($primary, 0.05),
    #fff,
    rgba($primary, 0.05)
  );
  background-size: 300%;
  animation: gradient 5s ease infinite;
  border-radius: 10px;
  margin-bottom: 1rem;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 1rem), calc(-50% - 1rem));
  max-width: 300px;
  border: 0;
  outline: 0;
  padding: 2rem;
  margin: 0 1rem;
  background-color: white;
  box-shadow: $boxShadow;
  border-radius: 10px;

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  h2 {
    margin: 0;
  }

  p {
    margin-bottom: 2.5rem;
  }

  .fileButtonRow {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .fileButton {
      position: relative;
      width: fit-content;
      border: 0;
      padding: 0;
      background-color: transparent;
      cursor: pointer;

      img {
        height: 80px;
        transition: opacity 0.2s ease;
      }

      .icon {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        img {
          opacity: 0.5;
        }

        .icon {
          display: block;
        }
      }

      &.fileButtonLoading {
        img {
          opacity: 0.5;
        }

        .overlayLoading {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
