@import 'colors';
@import 'breakpoints';

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba($primary, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;

  .container {
    position: relative;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    box-shadow: 0px 0px 10px rgba($primary, 0.2);
    margin: 1rem;

    .close {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .content {
      padding: 1rem;
      @include sm {
        padding: 2rem;
      }

      .illu {
        display: block;
        text-align: center;

        img {
          width: 85%;
          @include sm {
            width: 50%;
            //padding-left: 2rem;
          }
        }
      }

      .text {
        height: 22rem;
        overflow-y: scroll;
        background-color: rgba($primary, 0.05);
        padding: 0 1rem;
        border-radius: 10px;

        &::-webkit-scrollbar {
          background-color: rgba($primary, 0.1);
          height: 1px;
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba($secondary, 0.4);
          &:hover {
            background-color: rgba($secondary, 1);
          }
        }
        scrollbar-width: thin;
      }
    }
  }
  &.displayNone {
    display: none;
  }
}
