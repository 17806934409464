@import 'colors';
@import '~@fontsource/source-sans-pro/400.css';
@import '~@fontsource/source-sans-pro/600.css';

body {
  color: $primary;
  font-family: 'Source Sans Pro', sans-serif;
}
button,
a {
  color: $primary;
}

button:focus {
  outline: 0;
}
