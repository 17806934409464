.formControl {
  width: 100%;
}
.spacer {
  height: 1rem;
}
.loading {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
