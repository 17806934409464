@import 'colors';
@import 'breakpoints';

.container {
  position: relative;
}
.row {
  display: flex;
  overflow-x: auto;
  padding: 1rem;
  margin: -1rem;
  @include sm {
    margin-right: -1.5rem;
  }
  @include lg {
    margin-right: 0;
  }
  margin-bottom: 2rem;

  &::-webkit-scrollbar {
    background-color: rgba($primary, 0.1);
    height: 3px;
  }
  &::-webkit-scrollbar-button {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($secondary, 0.4);
    &:hover {
      background-color: rgba($secondary, 1);
    }
  }
  scrollbar-width: thin;

  .item {
    padding-right: 1rem;
  }
}
.arrow {
  background-color: rgba($secondary, 0.2);
  border-radius: 2rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  @include sm {
    right: 1.5rem;
  }
  top: 50%;
  svg {
    width: 2rem;
    height: 2rem;
  }
}
