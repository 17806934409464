@import 'colors';
@import 'breakpoints';

.container {
  color: $secondary;
  display: flex;
  align-items: center;
  margin-top: -1rem;
  max-width: 350px;
  line-height: 1.3em;
  svg {
    margin-right: 0.5rem;
  }
}
