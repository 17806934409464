@import 'colors';
@import 'breakpoints';

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 6rem;
}
.logo {
  width: 100px;
  margin: 2.5rem 0 2rem 0;
}

amplify-authenticator {
  --border-radius: 10px;
  --container-height: auto;
  --box-shadow: none;
}

:root {
  --amplify-primary-color: #eb6617;
  --amplify-secondary-color: #003169;
  --amplify-light-grey: #0031695e;
  --amplify-font-family: 'Source Sans Pro', sans-serif;
  --amplify-text-md: 1em;
  --amplify-text-md-sub: 1.5em;
}
