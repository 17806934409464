@import 'colors';
@import 'breakpoints';

.container {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  width: 300px;
  height: 220px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
