@import 'colors';

.container {
  border: 1px solid rgba($primary, 0.2);
  border-radius: 10px;
  padding: 16.5px 14px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
  > div {
    width: 45%;
  }
  > span {
    width: 10%;
  }
}
