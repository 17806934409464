@import 'colors';
@import 'breakpoints';

.container {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 4rem;
  @include sm {
    flex-flow: row nowrap;
    margin-top: 6rem;
  }
}
.sidebar {
  margin-bottom: 2rem;
  @include sm {
    width: 25rem;
    min-height: 25rem;
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 1px solid rgba($primary, 0.1);
  }
}
.list {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;

  .buttons {
    button {
      border: 0;
      background-color: transparent;
      opacity: 0.5;
      cursor: pointer;
      &.active {
        text-decoration: underline !important;
        opacity: 1;
      }
    }
  }
  .listItems {
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    padding-bottom: 2rem;
  }
}
.loading {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
