.container {
  .value {
    display: block;
    line-height: 1.1rem;
    font-weight: 600;
    font-size: 1rem;
  }
  &.large {
    .value {
      font-size: 1.1rem;
    }
  }
  &.horizontal {
    label,
    .value {
      display: inline-block;
    }
    .value {
      margin-left: 1rem;
    }
  }
}
